import { useState } from 'react';

import { useOnOutsideClick } from '@/hooks/useOutsideClick';

import { Icon } from '../Icon';
import { Button } from './Button';

type OpenInOverlayProps = {
  preview: React.ReactNode;
  children: React.ReactNode;
};

export default function OpenInOverlay(props: OpenInOverlayProps) {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  const { innerBorderRef } = useOnOutsideClick(close);

  const { preview, children } = props;

  return (
    <>
      <div className="cursor-pointer" onClick={open}>
        {preview}
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur">
          <div className="relative flex size-full items-center justify-center">
            <div className="flex max-h-[90vh] max-w-[90vw] [&>*]:object-contain" ref={innerBorderRef}>
              {children}
            </div>
            <Button
              className="absolute right-4 top-4 bg-gray-medium/50 text-gray-light2/50 hover:bg-fuxs-orange/75 hover:text-gray-medium "
              onClick={close}
            >
              <Icon name="close" className="text-2xl" />
              <span className="sr-only">Schliessen</span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
