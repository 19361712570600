import { useNavigate } from 'react-router-dom';

import type { Material } from '@backend/model/materials/types';
import { api } from '@/api';
import { useUpdateMessageState } from '@/MessageProvider';
import MaterialForm from './components/MaterialForm';

export function NewMaterialPage() {
  const updateMessageState = useUpdateMessageState();
  const navigate = useNavigate();

  function onCancel() {
    navigate('..');
  }

  const saveForm = async (data: Material) => {
    console.log('Saving', data);
    try {
      await api.newMaterial(data);
      updateMessageState({
        text: 'Das Material wurde erfolgreich angelegt.',
        level: 'success',
      });
      navigate('..');
    } catch (error) {
      console.error(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Neues Material</h1>
      <MaterialForm onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
