import { SubmitHandler } from 'react-hook-form';

import { Input, Image } from '@/components/common';
import { EntityForm } from '@/components/common/form/EntityForm';
import { Label } from '@/components/common/form/Label';
import { Offer } from '../utils';
import { Chooser, ItemQuery } from '@/components/common/form/Chooser';
import EntityTable from '@/components/EntityTable';
import { getCompetencyColumns } from '@/curriculum/competencies/utils';
import { Subject } from '@backend/model/subjects';
import { CompetenciesFilter, CompetencyGroup } from '@backend/model/competencies/types';
import { useState } from 'react';
import CompetenciesFilterForm from '@/curriculum/competencies/components/CompetenciesFilterForm';
import { COMPETENCIES_DEFAULT_ORDER_BY, COMPETENCIES_PAGE_SIZE } from '@/curriculum/competencies/CompentenciesListPage';
import { api } from '@/api';

export type OfferFormProps = {
  offer?: Partial<Offer>;
  subjects: Subject[];
  groups: CompetencyGroup[];
  onSubmit: SubmitHandler<Offer>;
  onCancel?: () => void;
};

export default function OfferForm(props: OfferFormProps) {
  const { offer, subjects, groups, onCancel, onSubmit } = props;

  const competencyColumns = getCompetencyColumns(subjects, groups);

  const [competenciesFilter, setCompetenciesFilter] = useState<CompetenciesFilter>({});

  const competenciesQueryFn = (query: ItemQuery, filter?: CompetenciesFilter) => {
    const params = {
      orderBy: query.orderBy,
      offset: (query.page - 1) * COMPETENCIES_PAGE_SIZE,
      limit: COMPETENCIES_PAGE_SIZE,
    };
    return api.findCompetencies(params, filter);
  };

  return (
    <EntityForm entity={offer} onCancel={onCancel} onSubmit={onSubmit} showId>
      {/* TODO: Make field types dependent on the selected material type */}

      <Label name="Name">
        <Input name="label" placeholder="Name" required />
      </Label>

      <Label name="Beschreibung">
        <Input name="description" placeholder="Beschreibung" />
      </Label>

      <Label name="Bild">
        <Image name="image" folder="/Angebote" width="800" height="800" />
      </Label>

      <Label name="Kompetenzen">
        <Chooser
          name="competencyIds"
          transientProp="competencies"
          title="Kompetenzen auswählen"
          itemsRenderer={(items) => <EntityTable className="w-full" columns={competencyColumns} rows={items} />}
          filter={competenciesFilter}
          filterForm={
            <CompetenciesFilterForm
              subjects={subjects}
              groups={groups}
              filter={competenciesFilter}
              onChange={setCompetenciesFilter}
              className="flex-col sm:flex-row"
            />
          }
          defaultOrderBy={COMPETENCIES_DEFAULT_ORDER_BY}
          itemQueryFn={competenciesQueryFn}
          chooserColumns={competencyColumns}
          choosenProp="id"
          onShow={() => setCompetenciesFilter({})}
          chooseMax={Infinity}
        />
      </Label>
    </EntityForm>
  );
}
