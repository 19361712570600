import { EntityTableColumn } from '@/components/EntityTable';
import { Icon } from '@/components/Icon';
import { thumbnailRenderer } from '@/helper';
import { SyntheticEvent } from 'react';
// import { thumbnailRenderer } from '@/helper';

export const getRandomOfferImage = () => {
  const availableImages = [
    'defaultImage.small_1024_768_nofilter.jpg',
    'dummy-100x100-Map.jpg',
    'dummy-200x200-Circus.jpg',
    'dummy-315x560-Goemetry.jpg',
    'dummy-375x500-Floral.jpg',
    'dummy-454x280-DesiccationCracks.jpg',
    'dummy-480x270-Comb.jpg',
    'dummy-540x960-Mosque.jpg',
    'dummy-576x1024-Menu.jpg',
    'dummy-600x900-RedDots.jpg',
    'dummy-683x1024-Stones.jpg',
  ];

  if (Math.random() > 0.5) {
    const img = availableImages[Math.floor(Math.random() * availableImages.length)];
    return `/Angebote/${img}`;
  }
};

export type Offer = {
  id: number;
  label: string;
  description: string;
  image?: string;
};

export const items: Offer[] = [
  {
    id: 4,
    label: 'Dinotopia',
    description: 'Wir basteln eine Zeitmaschine, durch die wir in das Land der Dinosaurier eintauchen.',
    image: getRandomOfferImage(),
  },
  {
    id: 3,
    label: 'Richtig schreiben',
    description: 'Wir üben das Schreiben von Wörtern und Sätzen.',
    image: getRandomOfferImage(),
  },
  {
    id: 2,
    label: 'Fussball am Mittag',
    description: 'Wir trainieren weiter, um...',
    image: getRandomOfferImage(),
  },
  {
    id: 1,
    label: 'Angebot vom Montag',
    description: 'Heute wollen wir das folgende tun: Wir wollen...',
    image: getRandomOfferImage(),
  },
];

export const getOffersTableColumns = ({
  getHandleViewOffer,
}: {
  getHandleViewOffer?: (id: number) => (ev: SyntheticEvent | Event) => void;
} = {}): EntityTableColumn<Offer>[] => {
  const columns: EntityTableColumn<Offer>[] = [
    {
      label: 'ID',
      prop: 'id',
      sortable: true,
      showSize: 'md',
    },
    {
      label: 'Name',
      prop: 'label',
      sortable: true,
    },
    {
      label: 'Bild',
      prop: 'image',
      renderer: thumbnailRenderer,
    },
    {
      label: 'Beschreibung und Ort',
      prop: 'description',
      renderer: (description /*, row: Offer */) => (
        <>
          <p className="line-clamp-1" title={description}>
            {description || <>&nbsp;</>}
          </p>
          {/* {row.location && (
          <p className="line-clamp-1" title={row.location}>
            {row.location}
          </p>
        )}
        {row.url && (
          <p className="line-clamp-1 underline" title={row.url}>
            {row.url}
          </p>
        )} */}
        </>
      ),
      showSize: 'sm',
    },
    // {
    //   label: 'Ort',
    //   prop: 'location',
    //   sortable: true,
    // },
    // {
    //   label: 'URL',
    //   prop: 'url',
    //   // TODO: Needs renderer
    // },
    // {
    //   label: 'Erstellt',
    //   prop: 'createdAt',
    //   renderer: dateTimeRenderer,
    //   sortable: true,
    //   showSize: 'xs',
    // },
    // {
    //   label: 'Erstellt von',
    //   prop: 'createdBy',
    //   sortable: true,
    // },
  ];

  if (getHandleViewOffer) {
    columns.push({
      label: '',
      prop: 'id',
      sortable: false,
      renderer: (id: number) => (
        <a className="flex items-center justify-center" onClick={getHandleViewOffer(id)}>
          <Icon className="text-2xl" name="visibility" />
        </a>
      ),
    });
  }

  return columns;
};
