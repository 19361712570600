import { SubmitHandler } from 'react-hook-form';

import type { NewSubject, Subject } from '@backend/model/subjects';
import { Image, Input } from '@/components/common';
import { EntityForm } from '@/components/common/form/EntityForm';
import { Label } from '@/components/common/form/Label';

export type SubjectFormProps = {
  subject?: Partial<NewSubject>;
  onSubmit: SubmitHandler<Subject>;
  onCancel?: () => void;
};

export default function SubjectForm(props: SubjectFormProps) {
  const { onCancel, onSubmit, subject } = props;

  return (
    <EntityForm entity={subject} onCancel={onCancel} onSubmit={onSubmit}>
      <Label name="Kürzel">
        <Input name="shortCode" placeholder="de" />
      </Label>

      <Label name="Name">
        <Input name="label" placeholder="Name" />
      </Label>

      <Label name="Bild">
        <Image name="image" folder="/Fächer" width="800" height="800" />
      </Label>
    </EntityForm>
  );
}
