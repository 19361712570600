import type { Competency } from '@backend/model/competencies/types';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { api } from '@/api';
import { useUpdateMessageState } from '@/MessageProvider';
import { SubjectsAndGroups } from '../loaders';
import CompetencyForm from './components/CompetencyForm';

export function NewCompetencyPage() {
  const loaderData = useLoaderData() as SubjectsAndGroups;
  const navigate = useNavigate();
  const updateMessageState = useUpdateMessageState();

  function onCancel() {
    navigate('..');
  }

  const saveForm = async (compentency: Competency) => {
    console.log('Saving', compentency);
    try {
      await api.newCompetency(compentency);
      updateMessageState({
        text: 'Die Kompetenz wurde erfolgreich gespeichert.',
        level: 'success',
      });
      navigate('..');
    } catch (error) {
      console.error(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Neue Gruppe</h1>
      <CompetencyForm {...loaderData} onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
