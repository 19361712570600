import type { Subject } from '@backend/model/subjects';
import { Input } from '@/components/common';
import { Dropdown } from '@/components/common/form/Dropdown';
import { QueryFilterForm } from '@/components/common/form/QueryFilterForm';

export type GroupsFilter = {
  search?: string;
  subjectId?: number;
};

type Props = {
  subjects: Subject[];
  className?: string;
};

export default function GroupsFilteForm(props: Props) {
  const { className, subjects } = props;

  return (
    <QueryFilterForm className={className}>
      <Input name="search" placeholder="Suche nach Name ..." />
      <Dropdown
        name="subjectId"
        entries={[{ value: '', label: 'Filtern auf Fach ...' }, ...subjects.map((s) => ({ value: s.id, label: s.label }))]}
      />
    </QueryFilterForm>
  );
}
