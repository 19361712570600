import { getFilter } from '@/helper';
import { CompetencyGroup } from '@backend/model/competencies/types';
import { Subject } from '@backend/model/subjects';
import { OffersFilter, OffersFilterForm } from './OffersFilterForm';
import { DeepPartial } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

type Props = {
  subjects: Subject[];
  groups: CompetencyGroup[];
  className?: string;
};

export default function QueryOffersFilterForm(props: Props) {
  const { className, subjects, groups } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const filter = getFilter<OffersFilter>(searchParams);

  const onChange = (value: DeepPartial<OffersFilter>) => {
    searchParams.set('filter', JSON.stringify(value));
    // NOTE: Resets the page to start at the beginning when filtering
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return <OffersFilterForm className={className} subjects={subjects} groups={groups} filter={filter} onChange={onChange} />;
}
