import Card, { CardRow, CardColumn } from '@/components/common/Card';
import { NextCloudImage } from '@/components/common/NextCloudImage';
import OpenInOverlay from '@/components/common/OpenInOverlay';
import { Offer } from '@/entities/offers/utils';
import { QRCodeSVG } from 'qrcode.react';

export function StudentsOfferCard({ offer, showSmall }: { offer: Offer; showSmall?: boolean }) {
  const renderQRCode = ({ className }: { className?: string } = {}) => {
    return (
      <QRCodeSVG
        className={className}
        // TODO: Sign-in link
        value={'https://picturesofpeoplescanningqrcodes.tumblr.com/'}
        title={'Title for my QR Code'}
        size={256}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        imageSettings={{
          src: '/logo.png',
          x: undefined,
          y: undefined,
          height: 48,
          width: 48,
          opacity: 1,
          excavate: true,
        }}
      />
    );
  };

  return (
    <Card>
      <CardRow className="mb-4 flex-col justify-between sm:flex-row">
        <CardColumn title="Angebotstitel" className="flex-grow" titleClassName="text-sm">
          <p>{offer.label}</p>
        </CardColumn>
      </CardRow>
      <CardRow className="mb-4 flex-col last:mb-0  justify-between sm:flex-row">
        <CardColumn title="Beschreibung" className="flex-grow" titleClassName="text-sm">
          <p>{offer.description}</p>
        </CardColumn>
      </CardRow>
      {!showSmall && (
        <CardRow className="mb-4 flex-col last:mb-0 sm:flex-row justify-between">
          {offer.image && (
            <CardColumn title="Bild" titleClassName="text-sm">
              <OpenInOverlay preview={<NextCloudImage className="max-h-60" file={offer.image} />}>
                <NextCloudImage file={offer.image} />
              </OpenInOverlay>
            </CardColumn>
          )}
          <CardColumn title="QR-Code" titleClassName="text-sm">
            <OpenInOverlay preview={renderQRCode()}>{renderQRCode({ className: 'w-screen h-full' })}</OpenInOverlay>
          </CardColumn>
        </CardRow>
      )}
    </Card>
  );
}
