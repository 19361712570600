import { useLoaderData } from 'react-router';

import type { Subject } from '@backend/model/subjects';
import { Button } from '@/components/common';
import { NextCloudImage } from '@/components/common/NextCloudImage';

export function SubjectPage() {
  const subject = useLoaderData() as Subject;

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Fach</h1>
      <div className="mb-4 flex justify-end">
        <Button link={[location.pathname, 'edit'].join('/')}>Editieren</Button>
      </div>

      <div className="flex w-full flex-col gap-1">
        {/* <div>ID: {subject.id}</div> */}
        <h2 className="text-4xl font-bold">{subject.label}</h2>
        <h3 className="text-2xl">
          {subject.shortCode} ({subject.id})
        </h3>
        {subject?.image && <NextCloudImage file={subject.image} alt={subject.image} width="800" height="800" />}
      </div>
    </div>
  );
}
