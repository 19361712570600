import { useEffect } from 'react';
import { DefaultValues, FieldValues, useForm } from 'react-hook-form';

import type { User } from '@backend/model/users/types';
import { Form } from '@/components/common';
import { Dropdown } from '@/components/common/form/Dropdown';

type StudentsSwitcherProps<T> = {
  className?: string;
  students: User[];
  selection?: DefaultValues<T>;
  onChange?: (studentId: number) => void;
};

export function StudentsSwitcher<T extends FieldValues>(props: StudentsSwitcherProps<T>) {
  const { className, students, selection, onChange } = props;

  const form = useForm<T>({ defaultValues: selection });
  const { handleSubmit, watch } = form;

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        const { studentId } = value;
        onChange?.(Number(studentId));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  const submitHandler = handleSubmit(() => {});

  const sortedStudents = students.sort((a, b) => a.displayName.localeCompare(b.displayName));

  return (
    <Form className={className} onSubmit={submitHandler} {...form}>
      <Dropdown name="studentId" entries={sortedStudents.map((s) => ({ value: s.id, label: s.displayName }))} />
    </Form>
  );
}
