import { useLoaderData, useLocation, useNavigate } from 'react-router';

import type { Competency } from '@backend/model/competencies/types';
import type { ServiceResult } from '@backend/service';
import { Button } from '@/components/common';
import EntityTable, { EntityTableColumn } from '@/components/EntityTable';
import { QueryPagination } from '@/components/QueryPagination';
import { getNewSortQueryString } from '@/helper';
import { useQuery } from '@/hooks/useQuery';
import { SubjectsAndGroups } from '../loaders';
import QueryCompetenciesFilterForm from './components/QueryCompetenciesFilterForm';
import { getCompetencyColumns } from './utils';

export const COMPETENCIES_DEFAULT_ORDER_BY = 'label:asc';
export const COMPETENCIES_PAGE_SIZE = 25;

export function CompetenciesListPage() {
  const {
    competencies: { result: items, total },
    subjects,
    groups,
  } = useLoaderData() as SubjectsAndGroups & { competencies: ServiceResult<Competency[]> };

  const columns = getCompetencyColumns(subjects, groups);

  const location = useLocation();
  const query = useQuery();

  const page = Number(query.get('page')) || 1;

  const navigate = useNavigate();
  const getRowOnClickHandler = (clicked: Competency) => () => {
    navigate([location.pathname, clicked.id].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<Competency>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), COMPETENCIES_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Kompetenzen</h2>
      <div className="mb-8 flex flex-col-reverse justify-end sm:flex-row">
        <QueryCompetenciesFilterForm className="grow flex-col sm:flex-row" subjects={subjects} groups={groups} />
        <Button link={[location.pathname, 'new'].join('/')} className="mb-4 sm:mb-0 sm:ml-4">
          Neue Kompetenz
        </Button>
      </div>
      {!items.length ? (
        <p>Keine Kompetenzen gefunden</p>
      ) : (
        <>
          <EntityTable
            columns={columns}
            rows={items}
            className="mb-8 w-full"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={COMPETENCIES_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </div>
  );
}
