import type { Material } from '@backend/model/materials/types';
import { MaterialType } from '@backend/model/materials/types';
import { EntityTableColumn } from '@/components/EntityTable';
import { dateTimeRenderer, thumbnailRenderer } from '@/helper';

export const MaterialTypeLabels = {
  [MaterialType.SelfLearn]: 'Selbstlern-Material',
  [MaterialType.Book]: 'Buch',
  [MaterialType.Document]: 'Dokument',
  [MaterialType.Exercise]: 'Übung',
  [MaterialType.App]: 'App',
  [MaterialType.Audio]: 'Audio',
  [MaterialType.Video]: 'Video',
  [MaterialType.Website]: 'Website',
  [MaterialType.Other]: 'Sonstiges',
};

export function getMaterialTypeLabel(type: MaterialType) {
  return MaterialTypeLabels[type];
}

export const materialTableColumns: EntityTableColumn<Material>[] = [
  {
    label: 'ID',
    prop: 'id',
    sortable: true,
    showSize: 'md',
  },
  {
    label: 'Typ',
    prop: 'type',
    renderer: (type) => getMaterialTypeLabel(type),
    sortable: true,
  },
  {
    label: 'Name',
    prop: 'label',
    sortable: true,
  },
  {
    label: 'Bild',
    prop: 'image',
    renderer: thumbnailRenderer,
  },
  {
    label: 'Beschreibung und Ort',
    prop: 'description',
    renderer: (description, row: Material) => (
      <>
        <p className="line-clamp-1" title={description}>
          {description || <>&nbsp;</>}
        </p>
        {row.location && (
          <p className="line-clamp-1" title={row.location}>
            {row.location}
          </p>
        )}
        {row.url && (
          <p className="line-clamp-1 underline" title={row.url}>
            {row.url}
          </p>
        )}
      </>
    ),
    showSize: 'sm',
  },
  // {
  //   label: 'Ort',
  //   prop: 'location',
  //   sortable: true,
  // },
  // {
  //   label: 'URL',
  //   prop: 'url',
  //   // TODO: Needs renderer
  // },
  {
    label: 'Erstellt',
    prop: 'createdAt',
    renderer: dateTimeRenderer,
    sortable: true,
    showSize: 'xs',
  },
  // {
  //   label: 'Erstellt von',
  //   prop: 'createdBy',
  //   sortable: true,
  // },
];
