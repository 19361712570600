import { useLoaderData, useNavigate } from 'react-router-dom';

import type { Subject } from '@backend/model/subjects';
import { api } from '@/api';
import { useUpdateMessageState } from '@/MessageProvider';
import SubjectForm from './components/SubjectForm';

export function EditSubjectPage() {
  const subject = useLoaderData() as Subject;
  const navigate = useNavigate();
  const updateMessageState = useUpdateMessageState();

  function onCancel() {
    navigate('..');
  }

  const saveForm = async (subject: Subject) => {
    console.log('Saving', subject);
    try {
      await api.updateSubject(subject);
      updateMessageState({
        text: 'Das Fach wurde erfolgreich gespeichert.',
        level: 'success',
      });
      navigate('..');
    } catch (error: unknown) {
      console.log(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Fach editieren</h1>
      <SubjectForm subject={subject} onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
