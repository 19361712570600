import { useLoaderData } from 'react-router';

import type { Material } from '@backend/model/materials/types';
import { Button } from '@/components/common';
import { MaterialComponent } from './components/MaterialComponent';

export function MaterialPage() {
  const material = useLoaderData() as Material;

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Material</h1>
      <div className="mb-4 flex justify-end">
        <Button link={[location.pathname, 'edit'].join('/')}>Editieren</Button>
      </div>

      <MaterialComponent material={material} />
    </div>
  );
}
