import type { Competency, CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';

import Card, { CardColumn, CardRow } from '@/components/common/Card';
import { Star } from '@/components/Icon';
import { cn } from '@/lib/utils';
import Avatar from '@/components/Avatar';
import type { User } from '@backend/model/users/types';

type WithSubjectAndGroup<T> = T & { subject: Subject; group: CompetencyGroup };

type Props = {
  students: User[];
  competency: WithSubjectAndGroup<Competency>;
  onChangeRating?: (competencyId: number, rating: number) => void;
};

type StudentRatingCardProps = {
  student: User;
  onChangeStudentRating: (student: User, rating: number) => void;
};

export default function StudentsCompetencyCard(props: Props) {
  const { competency, onChangeRating, students } = props;
  const { rating } = competency;
  const ratingClass = (star: number) => (rating && rating >= star ? 'fill-current' : 'fill-none');

  const starBaseClasses = 'w-8 cursor-pointer stroke-current stroke-2 p-0.5 text-fuxs-orange-dark transition-all hover:fill-current';

  const onChangeStudentRating = (student: User, star: number) => {
    let newRating = star;
    if (star === 1 && rating === 1) {
      newRating = 0;
    }
    console.log(`New rating for student ${student.id} and competency ${competency.id}`, newRating);
    // onChangeRating?.(competency.id, newRating);
  };

  // console.log('CompetencyCard', competency, rating);

  function CardTitle() {
    return (
      <span className="flex gap-1">
        <span>Kompetenz</span>
        <span className="text-gray-light">
          - {competency.subject.label} - {competency.group.label}
        </span>
      </span>
    );
  }

  function StudentRatingCard(props: StudentRatingCardProps) {
    const { student, onChangeStudentRating } = props;

    const onClick = (star: number) => {
      onChangeStudentRating(student, star);
    };

    return (
      <Card>
        <CardRow className="flex-row items-center">
          <CardColumn>
            <Avatar user={student} />
          </CardColumn>

          <CardColumn>
            <div className="ml-2">{student.displayName}</div>
          </CardColumn>

          <CardColumn className="flex-grow" />

          <CardColumn
            title="Einschätzung"
            className="self-start"
            titleClassName="text-sm justify-center mb-0.5"
            contentClassName="flex justify-center flex-row-reverse"
          >
            <Star className={cn(starBaseClasses, 'peer/three', ratingClass(3))} onClick={() => onClick(3)} />
            <Star
              className={cn(starBaseClasses, 'peer/two', ratingClass(2), 'hover:fill-current peer-hover/three:fill-current')}
              onClick={() => onClick(2)}
            />
            <Star
              className={cn(
                starBaseClasses,
                'peer/one',
                ratingClass(1),
                'hover:fill-current peer-hover/three:fill-current peer-hover/two:fill-current'
              )}
              onClick={() => onClick(1)}
            />
          </CardColumn>
        </CardRow>
      </Card>
    );
  }

  return (
    <Card>
      <CardRow className="mb-1 flex-col justify-between  xs:flex-row">
        <CardColumn title={<CardTitle />} titleClassName="text-sm">
          {competency.label}
        </CardColumn>
      </CardRow>
      <CardRow className="flex-col">
        <CardColumn title="Schülerinnen & Schüler" titleClassName="text-sm" expandable>
          {students.map((student) => (
            <StudentRatingCard key={student.id} student={student} onChangeStudentRating={onChangeStudentRating} />
          ))}
        </CardColumn>
      </CardRow>
    </Card>
  );
}
