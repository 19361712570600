import { JSX } from 'react';

import type { PlanDetails } from '@backend/model/plans/types';
import type { User } from '@backend/model/users/types';
import Tile from './Tile';

export type SchoolDeskProps = {
  user: User;
  plan: PlanDetails | null;
};
export default function StudentDesk(props: SchoolDeskProps): JSX.Element {
  const { user, plan } = props;

  return (
    <div className="size-full bg-hero bg-cover bg-no-repeat">
      <div className="grid size-full grid-flow-row-dense grid-cols-2 items-center bg-desk bg-contain bg-scroll bg-center bg-no-repeat bg-blend-exclusion sm:grid-cols-3 sm:bg-contain md:grid-cols-3 md:bg-auto lg:grid-cols-3 lg:bg-auto xl:grid-cols-3 xl:bg-auto">
        <div className="flex-grow">
          <Tile label="Website" imageSrc="/logo.png" link="https://www.fuxs.schule/" target="_blank" />
        </div>
        <div className="flex-grow">
          <Tile label="Zuständige" imageSrc="/tiles/people.svg" link="/people" />
        </div>
        <div className="flex-grow">
          <Tile label="Suche" imageSrc="/tiles/globe.svg" link="https://www.blinde-kuh.de/index.html" target="_blank" />
        </div>
        <div className="flex-grow">
          <Tile label="FuXs-Pfad" imageSrc="/tiles/fox.svg" link={`/paths/${user.id}`} />
        </div>
        <div className="flex-grow">
          <Tile label="Tagesbericht" imageSrc="/tiles/book.svg" link="/report" />
        </div>
        {plan && (
          <div className="flex-grow">
            <Tile label="Arbeitsplan" imageSrc="/tiles/sheet.svg" link={`/plan`} />
          </div>
        )}
        <div className="flex-grow">
          <Tile label="Lernspiel" imageSrc="/tiles/robot.png" link="/game" />
        </div>
      </div>
    </div>
  );
}
