import { useLoaderData } from 'react-router';

import type { User } from '@backend/model/users/types';
import { StudentTile } from './StudentTile';

type StudentsSelectorProps = JSX.IntrinsicElements['div'] & {
  onSelect?: (student: User) => void;
  selectedIds?: number[];
};

export function StudentsSelector(props: StudentsSelectorProps) {
  const students = useLoaderData() as User[];

  const { className, selectedIds, onSelect } = props;

  // console.log(JSON.stringify(students.filter((student) => selectedIds?.includes(student.id))));

  return (
    <div
      className={`grid gap-6 min-[320px]:grid-cols-1 min-[560px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5${className ? ` ${className}` : ''}`}
    >
      {students.map((student) => {
        const selected = selectedIds && selectedIds.includes(student.id);
        return <StudentTile key={student.id} selected={selected} student={student} onClick={() => onSelect && onSelect(student)} />;
      })}
    </div>
  );
}
