import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router';

import { Button } from '@/components/common';
import EntityTable, { EntityTableColumn } from '@/components/EntityTable';
import { getOffersTableColumns, items, Offer, offersTableColumns } from '@/entities/offers/utils';
import QueryOffersFilteForm from '@/entities/offers/components/QueryOffersFilterForm';
import type { Subject } from '@backend/model/subjects';
import type { CompetencyGroup } from '@backend/model/competencies/types';
import { getNewSortQueryString } from '@/helper';
import { useQuery } from '@/hooks/useQuery';
import { QueryPagination } from '@/components/QueryPagination';

export const OFFERS_PAGE_SIZE = 5;

export const OFFERS_DEFAULT_ORDER_BY = 'id:desc';

export function StudentsOffersPage() {
  // const { offerId } = useParams();
  // const { student, plans } = useLoaderData() as { student: User; plans?: ServiceResult<Plan[]> };
  const { subjects, groups } = useLoaderData() as {
    groups: CompetencyGroup[];
    subjects: Subject[];
  };

  const total = 2;

  const location = useLocation();
  const query = useQuery();

  const page = Number(query.get('page')) || 1;

  const navigate = useNavigate();
  const getRowOnClickHandler = (offer: Offer) => () => {
    navigate([location.pathname, offer.id].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<Offer>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), OFFERS_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Angebote</h2>
      <div className="mb-4 flex flex-col-reverse justify-end xs:flex-row">
        <QueryOffersFilteForm className="grow flex-col xs:flex-row" subjects={subjects} groups={groups} />
        <Button link={`/${['entities', 'offers', 'new'].join('/')}`} className="mb-4 sm:mb-0 sm:ml-4">
          Neues Angebot
        </Button>
      </div>
      {!items.length ? (
        <p>Keine Angebote gefunden</p>
      ) : (
        <>
          <EntityTable
            columns={getOffersTableColumns()}
            rows={items}
            className="mb-8 w-full"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={OFFERS_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </div>
  );
}
