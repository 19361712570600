import type { Material } from '@backend/model/materials/types';
import { NextCloudImage } from '@/components/common/NextCloudImage';
import { dateTimeRenderer } from '@/helper';
import { getMaterialTypeLabel } from '../utils';

type MaterialComponentProps = JSX.IntrinsicElements['div'] & {
  material?: Material;
};

export function MaterialComponent({ material, className }: MaterialComponentProps) {
  if (!material) {
    return <p>Kein Material</p>;
  }

  return (
    <div className={`${className} flex w-full flex-col gap-1`}>
      {/* <div>ID: {material.id}</div> */}
      <div>
        <b>Typ</b>: {getMaterialTypeLabel(material.type)}
      </div>
      <div>
        <b>Name</b>: {material.label}
      </div>
      {material.description && <div>{material.description}</div>}
      {material.location && <div>{material.location}</div>}
      {material.url && (
        <a className="underline" href={material.url} target="_blank" rel="noreferrer">
          {material.url}
        </a>
      )}
      {material.createdAt && (
        <div>
          <b>Erstellt am</b>: {dateTimeRenderer(material.createdAt)}
        </div>
      )}
      {material?.image && <NextCloudImage file={material.image} alt={material.image} width="800" height="800" />}
    </div>
  );
}
