import { useNavigate, useParams } from 'react-router';

import { items, Offer } from '@/entities/offers/utils';
import { Button } from '@/components/common';
import { StudentsOfferCard } from './components/StudentsOfferCard';

export function StudentsOfferPage() {
  const { offerId } = useParams();
  // const { student, plans } = useLoaderData() as { student: User; plans?: ServiceResult<Plan[]> };

  const offer: Offer = items[1];

  const navigate = useNavigate();
  const goToParticipants = () => {
    navigate([location.pathname, 'participants'].join('/'));
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Angebot</h2>

      <StudentsOfferCard offer={offer} />

      <Button onClick={goToParticipants}>Teilnehmer</Button>
    </div>
  );
}
