import type { Competency, CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { EntityTableColumn } from '@/components/EntityTable';

export const findSubject = (subjects: Subject[], subjectId: number) => subjects.find((s) => s.id === subjectId);

export const findGroup = (groups: CompetencyGroup[], groupId: number) => groups.find((g) => g.id === groupId);

export function getCompetencyColumns(subjects: Subject[], groups: CompetencyGroup[]): EntityTableColumn<Competency>[] {
  return [
    {
      label: 'ID',
      prop: 'id',
      sortable: true,
      showSize: 'md',
    },
    {
      label: 'Fach',
      prop: 'subjectId',
      sortable: true,
      renderer: (v) => findSubject(subjects, v)?.label || v,
    },
    {
      label: 'Grupe / Teilbereich',
      prop: 'groupId',
      sortable: true,
      renderer: (v) => findGroup(groups, v)?.label || v,
      showSize: 'sm',
    },
    {
      label: 'Kompentenz',
      prop: 'label',
      sortable: true,
      renderer: (description) => (
        <p className="line-clamp-1" title={description}>
          {description || <>&nbsp;</>}
        </p>
      ),
    },
    // REVISIT: Show?
    // {
    //   label: 'Beschreibung',
    //   prop: 'description',
    //   sortable: false,
    // },
  ];
}
