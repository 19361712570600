import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { SyntheticEvent } from 'react';

import { type Plan, PlanStatus, type PlanWithTasks, type UserPlanTask } from '@backend/model/plans/types';
import type { Task } from '@backend/model/tasks/types';
import { EntityCellRenderer, EntityTableColumn } from '@/components/EntityTable';
import { Icon } from '@/components/Icon';
import { dateTimeRenderer } from '@/helper';

export const planStatusRenderer = (status: PlanStatus | undefined) => {
  switch (status) {
    case PlanStatus.NEW:
      return 'In Vorbereitung';
    case PlanStatus.ACTIVE:
      return 'Aktiv';
    case PlanStatus.ARCHIVED:
      return 'Archiviert';
    default:
      return 'Unbekannt';
  }
};

export const getPlanStatusActionRenderer = ({
  editPlan,
  activatePlan,
  deletePlan,
}: {
  editPlan: (id: number) => void;
  activatePlan: (id: number) => void;
  deletePlan: (id: number) => void;
}) =>
  function planStatusActionRenderer(id: number, plan: Plan) {
    if (plan.status === PlanStatus.ARCHIVED) {
      return '';
    }

    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <a className="flex cursor-pointer items-center justify-center">
            <Icon className="text-2xl" name="more_vert" />
          </a>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="flex flex-row-reverse gap-2 rounded border border-black bg-gray-lightest p-2 shadow-lg shadow-gray-medium"
            sideOffset={-10}
            side="left"
          >
            {plan.status !== PlanStatus.ACTIVE && (
              <DropdownMenu.Item
                className="cursor-pointer rounded border border-black bg-action-green p-2 hover:bg-fuxs-orange-light"
                onSelect={() => activatePlan(id)}
              >
                <span className="flex items-center gap-2">
                  <Icon className="text-2xl" name="check_circle" />
                  <span>Aktivieren</span>
                </span>
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Item
              className="cursor-pointer rounded border border-black p-2 hover:bg-fuxs-orange-light"
              onSelect={() => editPlan(id)}
            >
              <span className="flex items-center gap-2">
                <Icon className="text-2xl" name="visibility" />
                <span>Bearbeiten</span>
              </span>
            </DropdownMenu.Item>
            {plan.status !== PlanStatus.ACTIVE && (
              <DropdownMenu.Item
                className="cursor-pointer rounded border border-black bg-error-red p-2 hover:bg-fuxs-orange-light"
                onSelect={() => deletePlan(id)}
              >
                <span className="flex items-center gap-2">
                  <Icon className="text-2xl" name="delete" />
                  <span>Löschen</span>
                </span>
              </DropdownMenu.Item>
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  };

export const getPlansTableColumns = (
  getHandleViewPlan?: (id: number) => (ev: SyntheticEvent | Event) => void,
  planStatusActionRenderer?: EntityCellRenderer<Plan>
): EntityTableColumn<Plan>[] => {
  const columns: EntityTableColumn<Plan>[] = [
    {
      label: 'ID',
      prop: 'id',
      sortable: true,
      showSize: 'sm',
    },
    {
      label: 'Ziel',
      prop: 'target',
      // sortable: true,
    },
    {
      label: 'Erstellt',
      prop: 'createdAt',
      renderer: dateTimeRenderer,
      sortable: true,
      showSize: 'xs',
    },
    {
      label: 'Erstellt von',
      prop: 'createdBy',
      // sortable: true,
      showSize: 'md',
    },
    {
      label: 'Status',
      prop: 'status',
      renderer: planStatusRenderer,
      sortable: true,
    },
  ];

  if (getHandleViewPlan) {
    columns.push({
      label: '',
      prop: 'id',
      sortable: false,
      renderer: (id: number) => (
        <a className="flex items-center justify-center" onClick={getHandleViewPlan(id)}>
          <Icon className="text-2xl" name="visibility" />
        </a>
      ),
    });
  }

  if (planStatusActionRenderer) {
    columns.push({
      label: '',
      prop: 'id',
      sortable: false,
      renderer: planStatusActionRenderer,
    });
  }

  return columns;
};

export const splitProjectsAndTasks = (plan?: Plan | PlanWithTasks) => {
  if (!plan) {
    return {
      tasks: [],
      taskIds: [],
      projects: [],
      projectIds: [],
    };
  }

  const tasks = plan.tasks.filter((task: Task | UserPlanTask) => task.type === 'normal');
  const projects = [...(plan.projects || []), ...plan.tasks.filter((task: Task | UserPlanTask) => task.type === 'project')];
  return {
    ...plan,
    tasks,
    taskIds: tasks.map((task: Task | UserPlanTask) => task.taskId || task.id),
    projects,
    projectIds: projects.map((task: Task | UserPlanTask) => task.taskId || task.id),
  };
};
