import { Outlet, useLocation } from 'react-router';

import { useUser } from '@/UserProvider';

import Message from '../components/common/Message';
import { TabNav, TabNavPropsItem } from '../components/TabNav';
import TopBar from '../components/TopBar';

export function EntitiesPage() {
  const user = useUser();

  const tabNav: TabNavPropsItem[] = [
    {
      label: 'Aufgaben',
      link: '/entities/tasks',
    },
    {
      label: 'Angebote',
      link: '/entities/offers',
    },
    {
      label: 'Projekte',
      link: '/entities/projects',
    },
    {
      label: 'Materialien',
      link: '/entities/materials',
    },
  ];

  // NOTE: Only for showing the prototype warning
  const location = useLocation();
  const isActive = (prefix: string, exact = false) => {
    const uri = location.pathname;
    if (exact) return uri === prefix;
    return uri.startsWith(prefix);
  };
  const isPrototype = isActive('/entities/offers');

  return (
    <div>
      <TopBar user={user} isPrototype={isPrototype} />

      <div className="mx-auto flex h-full flex-col p-4">
        <Message />

        <h1 className="mb-4 text-3xl font-bold">Sammlung / Katalog</h1>
        <TabNav className="mb-8 flex-col xs:flex-row" items={tabNav} />
        <Outlet />
      </div>
    </div>
  );
}
