import { Generated, Insertable, Selectable, Updateable } from 'kysely';
import type { Competency } from '../competencies/types.ts';
import type { Material } from '../materials/types.ts';
import type { Task } from "../tasks/types.ts";
import type { User } from '../users/types.ts';

export interface PlanTable {
  id: Generated<number>;
  userId: number;
  target: string;
  status?: PlanStatus;
  createdBy: number;
  createdAt?: Date;
  updatedAt?: Date;
}
export type Plan = Selectable<PlanTable>;
export type NewPlan = Insertable<PlanTable>;
export type PlanUpdate = Updateable<PlanTable>;

export enum PlanStatus {
  NEW = 'new',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export interface PlanTaskTable {
  planId: number;
  taskId: number;
  finished?: boolean;
  finishedAt?: Date;
  confirmed?: boolean;
  confirmedAt?: Date;
  confirmedBy?: number;
  createdAt?: Date;
  order?: number;
}

export type PlanTask = Selectable<PlanTaskTable>;

export type UserPlanTask = PlanTask &
  Omit<Task, 'createdAt'> & {
    taskCreatedAt?: Date;
  };

export type PlanWithTasks = Plan & { tasks: UserPlanTask[] };

export type DetailedUserPlanTask = UserPlanTask & { material?: Material | null; competencies: Competency[] };

export type PlanDetails = Plan & { user: User; tasks: DetailedUserPlanTask[] };
