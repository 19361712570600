import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { type Task, TaskType } from '@backend/model/tasks/types';
import { EntityTableColumn } from '@/components/EntityTable';
import { Icon } from '@/components/Icon';
import { dateTimeRenderer, thumbnailRenderer } from '@/helper';

export const TASK_TEXTS = {
  [TaskType.Normal]: {
    listTitle: 'Aufgaben',
    itemTitle: 'Aufgabe',
    newItem: 'Neue Aufgabe',
    newSaveSuccess: 'Die Aufgabe wurde erfolgreich angelegt.',
    editItem: 'Aufgabe bearbeiten',
    editSaveSuccess: 'Die Aufgabe wurde erfolgreich gespeichert.',
    noItemsFound: 'Keine Aufgaben gefunden',
    mainLabel: 'Aufgabe',
  },
  [TaskType.Project]: {
    listTitle: 'Projekte',
    itemTitle: 'Projekt',
    newItem: 'Neues Projekt',
    newSaveSuccess: 'Das Projekt wurde erfolgreich angelegt.',
    editItem: 'Projekt bearbeiten',
    editSaveSuccess: 'Das Projket wurde erfolgreich gespeichert.',
    noItemsFound: 'Keine Projekte gefunden',
    mainLabel: 'Name',
  },
};

const getTaskTableActionRenderer = (deleteTask: (taskId: number) => void) =>
  function TaskTableActionRenderer(taskId: number) {
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <a className="flex cursor-pointer items-center justify-center">
            <Icon className="text-2xl" name="more_vert" />
          </a>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="flex flex-row-reverse gap-2 rounded border border-black bg-gray-lightest p-2 shadow-lg shadow-gray-medium"
            sideOffset={-10}
            side="left"
          >
            <DropdownMenu.Item
              className="cursor-pointer rounded border border-black bg-error-red p-2 hover:bg-fuxs-orange-light"
              onSelect={() => deleteTask(taskId)}
            >
              <span className="flex items-center gap-2">
                <Icon className="text-2xl" name="check_circle" />
                <span>Löschen</span>
              </span>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  };

export const getTasksTableColumns = (type: TaskType, deleteTask?: (taskId: number) => void): EntityTableColumn<Task>[] => {
  const columns: EntityTableColumn<Task>[] = [
    {
      label: 'ID',
      prop: 'id',
      sortable: true,
      showSize: 'md',
    },
    {
      label: type === TaskType.Normal ? 'Aufgabe' : 'Projekt',
      prop: 'text',
      renderer: (text) => (
        <p className="line-clamp-2" title={text}>
          {text}
        </p>
      ),
    },
    {
      label: '',
      prop: 'hidden',
      renderer: (hidden) => <Icon name={`visibility${hidden ? '_off' : ''}`} />,
    },
    {
      label: 'Material',
      prop: 'materialId',
      showSize: 'sm',
    },
    {
      label: 'Bild',
      prop: 'image',
      renderer: thumbnailRenderer,
    },
    {
      label: 'Beschreibung',
      prop: 'description',
      renderer: (description) => (
        <p className="line-clamp-2" title={description}>
          {description || '-'}
        </p>
      ),
      showSize: 'xs',
    },
    {
      label: 'Erstellt',
      prop: 'createdAt',
      renderer: dateTimeRenderer,
      sortable: true,
      showSize: 'sm',
    },
    // {
    //   label: 'Erstellt von',
    //   prop: 'createdBy',
    //   sortable: true,
    // },
  ];

  if (deleteTask) {
    const taskTableActionRenderer = getTaskTableActionRenderer(deleteTask);
    columns.push({
      label: '',
      prop: 'id',
      sortable: false,
      renderer: taskTableActionRenderer,
    });
  }

  return columns;
};
