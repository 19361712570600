import type { User } from '@backend/model/users/types';
import { Icon } from './Icon';

type AvatarProps = {
  user?: User;
  small?: boolean;
};

export default function Avatar(props: AvatarProps) {
  const { user, small = false } = props;

  if (!user) {
    return <Icon name="account_circle" className="text-4xl" />;
  }

  const { displayName, remoteId } = props.user;

  if (small) {
    const foo = 'className="size-6"';
  }

  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.style.display = 'none';
    const fallback = event.currentTarget.nextSibling as HTMLElement;
    if (fallback) {
      fallback.classList.remove('!hidden');
    }
  };

  return (
    <>
      <img
        className={`rounded-full ${small ? 'size-6' : 'size-12'}`}
        src={`https://cloud.fuxs.schule/avatar/${remoteId}/20`}
        alt={`Avatar ${displayName}`}
        onError={onError}
      />
      <Icon name="account_circle" className={`!hidden ${small ? 'text-2xl' : 'text-5xl'}`} />
    </>
  );
}
