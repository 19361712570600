import { useMemo } from 'react';
import { useLoaderData, useRevalidator } from 'react-router';

import type { DetailedUserPlanTask, PlanDetails, UserPlanTask } from '@backend/model/plans/types';
import { TaskType } from '@backend/model/tasks/types';
import { api } from '@/api';
import { CardWithColumns } from '@/components/common/Card';
import TaskCard from '@/components/TaskCard';
import TopBar from '@/components/TopBar';
import { dateRenderer } from '@/helper';

export function StudentPlanPage() {
  const plan = useLoaderData() as PlanDetails | null;
  if (!plan) {
    throw new Error('Kein Plan gefunden');
  }

  const revalidator = useRevalidator();
  const toggleTask = async (taskId: number) => {
    const task = plan.tasks.find((t: DetailedUserPlanTask) => t.id === taskId);
    if (!task) {
      console.warn('Did not find task in plan');
      return;
    }

    await api.updatePlanTask(plan.id, taskId, { finished: !task['finished'] });
    revalidator.revalidate();
  };

  const orderedTasks = useMemo(
    () =>
      plan.tasks
        .filter((t: DetailedUserPlanTask) => t.type === TaskType.Normal)
        .sort((a: DetailedUserPlanTask, b: UserPlanTask) => (a.order || 1) - (b.order || 1)),
    [plan.tasks]
  );

  const orderedProjects = useMemo(
    () =>
      plan.tasks
        .filter((t: DetailedUserPlanTask) => t.type === TaskType.Project)
        .sort((a: DetailedUserPlanTask, b: UserPlanTask) => (a.order || 1) - (b.order || 1)),
    [plan.tasks]
  );

  return (
    <div>
      <TopBar user={plan.user} />

      <div className="mx-auto flex h-full flex-col p-4">
        <div>
          <h2 className="mb-4 text-2xl font-bold">Mein Arbeitsplan vom {dateRenderer(plan.updatedAt)}</h2>
        </div>

        <CardWithColumns columns={[{ title: 'Ziel', children: <p>{plan.target}</p> }]} />

        {orderedTasks.length > 0 && (
          <>
            <h2 className="mb-4 text-xl font-bold">Aufgaben</h2>
            {orderedTasks.map((task: DetailedUserPlanTask, i: number) => (
              <TaskCard key={task.id} position={i + 1} student={plan.user} task={task} toggleTaskFinished={toggleTask} />
            ))}
          </>
        )}

        {orderedProjects.length > 0 && (
          <>
            <h2 className="mb-4 mt-8 text-xl font-bold">Projekt(e)</h2>
            {orderedProjects.map((task: DetailedUserPlanTask, i: number) => (
              <TaskCard key={task.id} position={i + 1} student={plan.user} task={task} toggleTaskFinished={toggleTask} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
