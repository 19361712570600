import { useNavigate } from 'react-router-dom';

import type { Subject } from '@backend/model/subjects';
import { api } from '@/api';
import { useUpdateMessageState } from '@/MessageProvider';
import SubjectForm from './components/SubjectForm';

export function NewSubjectPage() {
  const updateMessageState = useUpdateMessageState();
  const navigate = useNavigate();

  function onCancel() {
    navigate('..');
  }

  const saveForm = async (data: Subject) => {
    console.log('Saving', data);
    try {
      await api.newSubject(data);
      updateMessageState({
        text: 'Das Material wurde erfolgreich angelegt.',
        level: 'success',
      });
      navigate('..');
    } catch (error) {
      console.error(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Neues Fach</h1>
      <SubjectForm onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
