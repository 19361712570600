import { tw, variants } from 'classname-variants';
import { JSX } from 'react';
import { NavLink } from 'react-router-dom';

type NavItemProps = JSX.IntrinsicElements['li'] & {
  active: boolean;
  link: string;
  target?: string;
  onClick?: (e: React.MouseEvent) => void;
};

export default function NavItem(props: NavItemProps) {
  const { active, className, children, link, target, onClick } = props;

  const classes = variants({
    base: tw``,
    variants: {
      active: {
        false: tw`hover:text-fuxs-orange`,
        true: tw`font-bold text-fuxs-orange`,
      },
    },
  });

  return (
    <li className={className}>
      <NavLink to={link} target={target} className={classes({ active })} onClick={onClick}>
        <span className="flex items-center gap-1">{children}</span>
      </NavLink>
    </li>
  );
}
