import { items, Offer } from '@/entities/offers/utils';
import type { User } from '@backend/model/users/types';
import type { Competency } from '@backend/model/competencies/types';
import StudentsCompetencyCard from './components/StudentsCompetencyCard';

export function StudentsOfferCompetenciesPage() {
  // const { offerId } = useParams();

  const offer: Offer = items[1];

  const competencies: Competency[] = [
    {
      id: 322,
      subjectId: 7,
      groupId: 35,
      label: 'du hältst grundlegende Regeln und Rituale beim Kämpfen ein',
      description: null,
      rating: null,
      tasks: [],
      subject: {
        id: 7,
        shortCode: 'sp',
        image: '/Fächer/sport.svg',
        label: 'Sport',
      },
      group: {
        id: 35,
        label: 'Kämpfen',
        order: 341,
        subjectId: 7,
      },
    },
    {
      id: 323,
      subjectId: 7,
      groupId: 35,
      label: 'du kannst fair gegen- und miteinander kämpfen',
      description: null,
      rating: null,
      tasks: [],
      subject: {
        id: 7,
        shortCode: 'sp',
        image: '/Fächer/sport.svg',
        label: 'Sport',
      },
      group: {
        id: 35,
        label: 'Kämpfen',
        order: 341,
        subjectId: 7,
      },
    },
    {
      id: 309,
      subjectId: 7,
      groupId: 32,
      label: 'du verwendest einfache Taktiken mannschaftsdienlich',
      description: null,
      rating: null,
      tasks: [],
      subject: {
        id: 7,
        shortCode: 'sp',
        image: '/Fächer/sport.svg',
        label: 'Sport',
      },
      group: {
        id: 32,
        label: 'Spielen',
        order: 311,
        subjectId: 7,
      },
    },
  ];

  const students: User[] = [
    {
      id: 35,
      remoteId: 'kasimir.laka',
      displayName: 'Kasimir Laka',
      groups: ['Kreis SchülerInnen', 'Kreis SchülerInnen GS'],
    },
    {
      id: 44,
      remoteId: 'julius.bellmann',
      displayName: 'Julius Bellmann',
      groups: ['Kreis SchülerInnen', 'Kreis SchülerInnen GS'],
    },
    {
      id: 8,
      remoteId: 'lene.bach',
      displayName: 'Lene Bach',
      groups: ['App User Mail', 'Kreis SchülerInnen', 'Kreis SchülerInnen GS'],
    },
  ];

  function onChangeRating() {
    console.log('onChangeRating');
  }

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Kompetenzen vom Angebot - {offer.label}</h2>
      <div>
        {competencies.map((competency) => (
          <StudentsCompetencyCard key={competency.id} students={students} competency={competency} onChangeRating={onChangeRating} />
        ))}
      </div>
    </div>
  );
}
