import { DeepPartial } from 'react-hook-form';

import { Input } from '@/components/common';
import { FilterForm } from '@/components/common/form/FilterForm';
import { Dropdown } from '@/components/common/form/Dropdown';
import { SwitchField } from '@/components/common/form/SwitchField';
import { useEffect, useState } from 'react';
import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';

export type OffersFilter = {
  search?: string;
  subjectId?: string;
  groupId?: string;
  competencyId?: string;
  // showHidden?: string;
};

type Props = {
  className?: string;
  subjects: Subject[];
  groups: CompetencyGroup[];
  filter?: DeepPartial<OffersFilter>;
  onChange?: (value: DeepPartial<OffersFilter>) => void;
};

export function OffersFilterForm(props: Props) {
  const { className, subjects, groups, filter, onChange } = props;

  const [filteredGroups, setFilteredGroups] = useState<CompetencyGroup[]>(groups);
  useEffect(() => {
    const { groupId, subjectId } = filter || {};

    if (!subjectId) {
      setFilteredGroups(groups);
    } else {
      // REVIST: subjectId is not a number
      const filtered = groups.filter((g) => g.subjectId === Number(subjectId));
      setFilteredGroups(filtered);

      // NOTE: Reset groupId if not in filtered groups, i.e. if filtered before and then subjectId changes
      if (groupId && !filtered.find((f) => f.id === Number(groupId))) {
        onChange?.({ ...filter, groupId: '' });
      }
    }
  }, [filter, groups, onChange]);

  return (
    <FilterForm className={className} onChange={onChange} filter={filter}>
      <Input name="search" placeholder="Suche ..." />
      <Dropdown
        name="subjectId"
        entries={[{ value: '', label: 'Filtern auf Fach ...' }, ...subjects.map((s) => ({ value: s.id, label: s.label }))]}
      />
      <Dropdown
        name="groupId"
        fieldOptions={{ required: 'Gruppe wählen' }}
        entries={[{ value: '', label: 'Filtern auf Gruppe ...' }, ...filteredGroups.map((g) => ({ value: g.id, label: g.label }))]}
      />
      {/* <SwitchField name="showHidden">Persönliche anzeigen</SwitchField> */}
    </FilterForm>
  );
}
