import { DeepPartial } from 'react-hook-form';

import type { MaterialsFilter } from '@backend/model/materials/types';
import { Input } from '@/components/common';
import { FilterForm } from '@/components/common/form/FilterForm';

type Props = {
  className?: string;
  filter: MaterialsFilter;
  onChange?: (value: DeepPartial<MaterialsFilter>) => void;
};

export function MaterialsFilterForm(props: Props) {
  const { className, filter, onChange } = props;

  return (
    <FilterForm className={className} onChange={onChange} filter={filter}>
      <Input name="search" placeholder="Suche ..." />
    </FilterForm>
  );
}
