import { FieldValues, SubmitHandler, useForm, UseFormProps, UseFormSetError } from 'react-hook-form';
import { RpcError } from 'typed-rpc';

import { ValidationErrorData } from '@backend/errors';
import { useErrorBoundary } from './useErrorBoundary';

// import { handleError } from '../utils/form';

export function markFields<T extends FieldValues>(error: RpcError, setError: UseFormSetError<T>) {
  const { data } = error;
  // REVISIT: Too easy assumption - we should type and guard this properly
  if (data instanceof Array) {
    for (const issue of error.data as ValidationErrorData[]) {
      const { field, message } = issue;
      if (typeof field === 'string' && message) {
        setError(field as never, { message });
      }
    }
  }
}

function handleError<T extends FieldValues>(err: unknown, setError: UseFormSetError<T>) {
  // REVISIT: Too easy assumption - we should type and guard this properly
  if (err && err instanceof RpcError && err.data instanceof Array) {
    markFields(err as RpcError, setError);
  } else {
    throw err;
  }
}

type Props<T extends FieldValues> = UseFormProps<T> & {
  onSubmit: SubmitHandler<T>;
};

export function useFormSubmission<T extends FieldValues>({ onSubmit, ...props }: Props<T>) {
  const form = useForm<T>(props);
  const { handleSubmit, setError } = form;

  // REVISIT: Do we need a useCallback here?
  const submitHandler = handleSubmit(
    useErrorBoundary(async (values) => {
      try {
        await onSubmit(values);
      } catch (err: unknown) {
        handleError(err, setError);
      }
    })
  );

  return {
    ...form,
    onSubmit: submitHandler,
  };
}
