import { useLoaderData, useNavigate } from 'react-router-dom';

import { useUpdateMessageState } from '@/MessageProvider';
import OfferForm from './components/OfferForm';
import { getRandomOfferImage, Offer } from './utils';
import { CompetencyGroup } from '@backend/model/competencies/types';
import { Subject } from '@backend/model/subjects';

export function EditOfferPage() {
  const { subjects, groups } = useLoaderData() as {
    groups: CompetencyGroup[];
    subjects: Subject[];
  };

  const offer: Offer = {
    id: '2',
    label: 'Fussball am Mittag',
    description: 'Wir trainieren weiter, um...',
    image: getRandomOfferImage(),
  };

  const navigate = useNavigate();
  const updateMessageState = useUpdateMessageState();

  function onCancel() {
    navigate('../..');
  }

  const saveForm = async (offer: Offer) => {
    console.log('Saving', offer);
    try {
      // await api.updateMaterial(offer);
      updateMessageState({
        text: 'Das Angebot wurde erfolgreich gespeichert.',
        level: 'success',
      });
      navigate('../..');
    } catch (error: unknown) {
      console.log(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Angebot editieren</h1>
      <OfferForm offer={offer} subjects={subjects} groups={groups} onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
