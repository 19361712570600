import { useNavigate, useParams } from 'react-router';

import { items, Offer } from '@/entities/offers/utils';
import { StudentsSelector } from '../components/StudentsSelector';
import { useEffect, useState } from 'react';
import type { User } from '@backend/model/users/types';
import { Button } from '@/components/common';
import { StudentsOfferCard } from './components/StudentsOfferCard';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Icon } from '@/components/Icon';

const prototypeSelectedToday = [8, 35, 44];

export function StudentsOfferParticipantsPage() {
  // const { offerId } = useParams();

  const offer: Offer = items[1];

  // TODO: Selected Ids coming also from loader data
  const [selectedIds, setSelectedIds] = useState<number[]>(prototypeSelectedToday);

  const onSelectStudent = (student: User) => {
    if (selectedIds.includes(student.id)) {
      setSelectedIds(selectedIds.filter((id) => id !== student.id));
    } else {
      setSelectedIds([...selectedIds, student.id]);
    }
  };

  const navigate = useNavigate();
  const goToCompentencies = () => {
    // /students/offers/2/participants/competencies
    navigate(['..', offer.id, 'competencies'].join('/'));
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsDate = searchParams.get('date');
  const selectionDate = searchParamsDate ? new Date(searchParamsDate) : new Date();
  const isToday = format(new Date(), 'yyyy-MM-dd') === format(selectionDate, 'yyyy-MM-dd');

  const changeDay = (direction?: -1 | 1) => () => {
    if (!direction) {
      setSearchParams({ date: format(new Date(), 'yyyy-MM-dd') });
      return;
    }
    const newDate = new Date(selectionDate);
    newDate.setDate(newDate.getDate() + direction);
    setSearchParams({ date: format(newDate, 'yyyy-MM-dd') });
    // NOTE: Just for showing the date change
    const number = Math.floor(Math.random() * 5);
    const newSelectedIds = Array.from({ length: number }, () => Math.floor(Math.random() * 50));
    setSelectedIds(newSelectedIds);
  };

  // NOTE: Just for showing the date change
  useEffect(() => {
    if (isToday) {
      setSelectedIds(prototypeSelectedToday);
    }
  }, [searchParamsDate]);

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-2xl font-bold">Teilnehmer</h2>
        <span className="text-xl flex items-center gap-2">
          <Button onClick={changeDay(-1)}>
            <Icon name="arrow_back" />
          </Button>
          <span>{format(selectionDate, 'dd.MM.yyyy')}</span>
          {!isToday && (
            <>
              <Button onClick={changeDay(1)}>
                <Icon name="arrow_forward" />
              </Button>
              <Button onClick={changeDay()}>Heute</Button>
            </>
          )}
        </span>
      </div>
      <StudentsOfferCard offer={offer} showSmall={true} />
      <StudentsSelector className="mb-4" selectedIds={selectedIds} onSelect={onSelectStudent} />
      <Button onClick={goToCompentencies}>Kompetenzen</Button>
    </div>
  );
}
